import type { Plugin, InjectionKey } from "vue";

export interface Synchroniser {
    resync(): void;
}

export const SyncWorker = Symbol() as InjectionKey<Synchroniser>;

export const SyncPlugin: Plugin = {
    install: (app, options) => {
        const worker = new SharedWorker(new URL("./../worker.ts", import.meta.url), { type: "module" });

        worker.port.start();
        worker.port.onmessage = (e: MessageEvent<any>) => {
            console.log(e);
        };
        worker.port.postMessage(["ayyy lmao"]);

        const wrappedWorker = {
            resync: () => worker.port.postMessage("resync")
        } as Synchroniser;

        app.provide(SyncWorker, wrappedWorker);
    }
};
